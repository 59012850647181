<template>
  <div>
    <div class="text-center">
      <h2 v-if="dailyGame" class="text-center card text-white resultHeader py-2">
        {{ dailyGame.name }}
      </h2>
      <table class="table_panel" width="90%">
        <thead class="table_header">
          <th>Date</th>
          <th>Mon</th>
          <th>Tue</th>
          <th>Wed</th>
          <th>Thu</th>
          <th>Fri</th>
          <th v-if="dailyGame.days == 6 || dailyGame.days == 7">Sat</th>
          <th v-if="dailyGame.days == 7">Sun</th>
        </thead>
        <tr
          class="dailyPanel"
          v-for="panelRecord in panelRecords"
          :key="panelRecord.id"
        >
          <td>
            <div>
              <span>{{ changeDateFormat(panelRecord.from_date) }}</span>
              <br />
              <span>to</span>
              <br />
              <span>{{ changeDateFormat(panelRecord.to_date) }}</span>
            </div>
            <hr>
          </td>

          <td v-html="recordObject(panelRecord.monday)"></td>
          <td v-html="recordObject(panelRecord.tuesday)"></td>
          <td v-html="recordObject(panelRecord.wednesday)"></td>
          <td v-html="recordObject(panelRecord.thursday)"></td>
          <td v-html="recordObject(panelRecord.friday)"></td>
          <td v-html="recordObject(panelRecord.saturday)"></td>
          <td v-html="recordObject(panelRecord.sunday)"></td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script>
import axiosObject from '../../axiosHandler';
export default {
  data() {
    return {
      dailyGame: Object,
      panelRecords: Object,
    };
  },
  created() {
    this.getPanel(this.$route.params.id);
  },
  methods: {
    getPanel(id) {
      axiosObject.get("/api/panel/" + id)
        .then(
          function (response) {
            this.dailyGame = response.data.daily_game;
            this.panelRecords = response.data.panel_records;
          }.bind(this)
        )
        .catch(function (error) {
         alert(error.response.data.message);
          if(error.response.status == 401){
            localStorage.clear();
            window.location.reload()
          };
        });
    },

    changeDateFormat(date) {
      return new Date(date).toLocaleDateString("en-IN");
    },
    recordObject(data) {
      if (!data) return;
      const value = new String(data);
      if (!value[9]) return;
      const comboValue = parseInt(value[4]) - parseInt(value[5]);
      if (comboValue == 5 || comboValue == -5 || comboValue == 0) {
        return (
         
          '<div><p align="center" class="redword m-1 p-2">' +
          value[4] +
          value[5] +
          "</p></div>" +
          "<hr>"
        );
      }

      return (
        
        '<div><p align="center" class="whiteword m-1 p-2">' +
        value[4] +
        value[5] +
        
        "<hr>"
      );
    },
  },
};
</script>

<style scoped>
.redword {
  color: red;
  font-size: 14px;
  font-weight: bold;
}
.dailyPanel > td {
  font-size: 10px;
  font-weight: bold;
}
.whiteword {
  font-size: 14px;
  font-weight: bold;
}
.table_panel {
  text-align: center;
  margin: 10px auto;
  color: white;
  font-size: 20px;
  border: solid 2px white;
  border-radius: 4px;
  background: red;
  
}

.table_panel > tr {
    background: rgb(255, 255, 255);
    color: rgb(0, 0, 0);
    
}
hr{
  margin:0
}
</style>
